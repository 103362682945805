import React, { useEffect, useState, useRef } from 'react'
import axios from 'axios'
import $ from 'jquery'
import PropTypes from 'prop-types'
import WOW from 'wow.js'
import 'animate.css'

function C01Notification(props) {
    const {
        classProps
    } = props

    const passwordRef = useRef(false)
    const eyeIcoRef = useRef()
    const [dataConsultaSaldo, setDataConsultaSaldo] = useState([])
    const [randomItem, setRandomItem] = useState([])
    const [username, setUserName] = useState('')
    const [password, setPassWord] = useState('')
    const [urlServicioWeb, setUrlServicioWeb] = useState(localStorage.getItem('url_servicio_web'))    
    const [notificacionHidden, setNotificacionHidden] = useState('')
    const [displayDefaultNoti, setDisplayDefaultNoti] = useState(localStorage.getItem('notificacion_default_login_hidden'))
    const [title, setTitle] = useState(localStorage.getItem('notificacion_default_login_title'))
    const [description, setDescription] = useState(localStorage.getItem('notificacion_default_login_description'))
    const [price, setPrice] = useState(localStorage.getItem('notificacion_default_login_price'))
    const [validity, setValidity] = useState(localStorage.getItem('notificacion_default_login_validity'))
    const [goTo, setGoTo] = useState(localStorage.getItem('notificacion_default_login_goTo'))
    const [urlGoTo, setUrlGoTo] = useState(localStorage.getItem('notificacion_default_login_urlGoTo'))
    const [targetGoTo, setTargetGoTo] = useState(localStorage.getItem('notificacion_default_login_targetGoTo'))

    const getValuesDefault = async()=>{
        urlServicioWeb === '' ? setUrlServicioWeb(localStorage.getItem('url_servicio_web')) : null
        notificacionHidden === '' ? setNotificacionHidden(localStorage.getItem('notificacion_hidden')) : 'true'
        displayDefaultNoti === '' ? setDisplayDefaultNoti(localStorage.getItem('notificacion_default_login_hidden')) : 'true'
        title       === '' ? setTitle(localStorage.getItem('notificacion_default_login_title'))                : null
        description === '' ? setDescription(localStorage.getItem('notificacion_default_login_description'))    : null
        price       === '' ? setPrice(localStorage.getItem('notificacion_default_login_price'))                : null
        validity    === '' ? setValidity(localStorage.getItem('notificacion_default_login_validity'))          : null
        goTo        === '' ? setGoTo(localStorage.getItem('notificacion_default_login_goTo'))                  : null
        urlGoTo     === '' ? setUrlGoTo(localStorage.getItem('notificacion_default_login_urlGoTo'))            : null
        targetGoTo  === '' ? setTargetGoTo(localStorage.getItem('notificacion_default_login_targetGoTo'))      : null
    }
    useEffect(() =>{
        getValuesDefault();
        // eslint-disable-next-line
    }, [])

    function validForm(formName, viewAlert = false) {
        let newform = $(`form[name="${formName}"]`)[0]
        if (newform) {
            for (let element of newform) {
                if (element.name) {
                    element.setAttribute("title", '');
                    element.addEventListener('invalid', (e) => eventSubmit(e, viewAlert), false);
                    element.addEventListener('keypress', () => removeElement(element.name));
                    element.addEventListener('change', () => removeElement(element.name));
                }
            }
        }
    }
    //Input message validation
    const INPUT_MESSAGE_REQUERID = 'Complete este campo'
    const EMAIL_MESSAGE_FORMAT = 'El formato de correo es inválido'
    const MESSAGE_TOO_SHORT = 'El mínimo de caracteres debe ser $value'
    const PATTERN_MESSAGE = 'Ingrese en formato de'//'Utilice un formato que coincida con el solicitado'
    const MESSAGE_TOO_LONG = 'El máximo de caracteres para el campo es de $value'
    const MESSAGE_RANGE_MAX = 'El rango máximo para el campo es de $value'
    const MESSAGE_RANGE_MIN = 'El rango minimo para el campo es de $value'
    const MESSAGE_STEP = 'El valor ingresado debe de ser múltiplo del $value'
    const MESSAGE_INVALID = 'El valor ingresado no es válido'
    const MESSAGE_TYPE_INVALID = 'El tipo de dato no es válido'
    const MESSAGE_BAD_INPUT = 'Valor no válido o no puede ser convertido'

    function eventSubmit(e, viewAlert) {
        e.preventDefault()
        const elementoAnterior = e.target
        elementoAnterior.setAttribute("title", '');
        const nameError = 'error' + elementoAnterior.name
        removeElement(elementoAnterior.name)
        let nuevoElemento = document.createElement('span');
        nuevoElemento.setAttribute("name", nameError);
        nuevoElemento.className = 'errorField light'
        if (elementoAnterior.validity.badInput) {
            nuevoElemento.appendChild(document.createTextNode(MESSAGE_BAD_INPUT));
            elementoAnterior.setAttribute("title", '');
            elementoAnterior.parentNode.insertBefore(nuevoElemento, elementoAnterior.nextSibling);
        } else if (elementoAnterior.validity.valueMissing) {
            nuevoElemento.appendChild(document.createTextNode(INPUT_MESSAGE_REQUERID));
            elementoAnterior.setAttribute("title", '');
            elementoAnterior.parentNode.insertBefore(nuevoElemento, elementoAnterior.nextSibling);
        } else if (elementoAnterior.validity.typeMismatch) {
            if (elementoAnterior.type === "email") {
                nuevoElemento.appendChild(document.createTextNode(EMAIL_MESSAGE_FORMAT));
                elementoAnterior.setAttribute("title", '');
                elementoAnterior.parentNode.insertBefore(nuevoElemento, elementoAnterior.nextSibling);
            } else {
                nuevoElemento.appendChild(document.createTextNode(MESSAGE_TYPE_INVALID));
                elementoAnterior.setAttribute("title", '');
                elementoAnterior.parentNode.insertBefore(nuevoElemento, elementoAnterior.nextSibling);
            }
        } else if (elementoAnterior.validity.patternMismatch) {
            const formato = elementoAnterior.dataset.pattern ? elementoAnterior.dataset.pattern : ''
            nuevoElemento.appendChild(document.createTextNode(PATTERN_MESSAGE + formato));
            elementoAnterior.setAttribute("title", '');
            elementoAnterior.parentNode.insertBefore(nuevoElemento, elementoAnterior.nextSibling);
        } else if (elementoAnterior.validity.tooLong) {
            nuevoElemento.appendChild(document.createTextNode(MESSAGE_TOO_LONG.replace("$value", elementoAnterior.maxLength)));
            elementoAnterior.setAttribute("title", '');
            elementoAnterior.parentNode.insertBefore(nuevoElemento, elementoAnterior.nextSibling);
        } else if (elementoAnterior.validity.tooShort) {
            nuevoElemento.appendChild(document.createTextNode(MESSAGE_TOO_SHORT.replace("$value", elementoAnterior.minLength)));
            elementoAnterior.setAttribute("title", '');
            elementoAnterior.parentNode.insertBefore(nuevoElemento, elementoAnterior.nextSibling);
        } else if (elementoAnterior.validity.rangeOverflow) {
            nuevoElemento.appendChild(document.createTextNode(MESSAGE_RANGE_MAX.replace("$value", elementoAnterior.max)));
            elementoAnterior.setAttribute("title", '');
            elementoAnterior.parentNode.insertBefore(nuevoElemento, elementoAnterior.nextSibling);
        } else if (elementoAnterior.validity.rangeUnderflow) {
            nuevoElemento.appendChild(document.createTextNode(MESSAGE_RANGE_MIN.replace("$value", elementoAnterior.min)));
            elementoAnterior.setAttribute("title", '');
            elementoAnterior.parentNode.insertBefore(nuevoElemento, elementoAnterior.nextSibling);
        } else if (elementoAnterior.validity.stepMismatch) {
            nuevoElemento.appendChild(document.createTextNode(MESSAGE_STEP));
            elementoAnterior.setAttribute("title", '');
            elementoAnterior.parentNode.insertBefore(nuevoElemento, elementoAnterior.nextSibling);
        } else {
            nuevoElemento.appendChild(document.createTextNode(MESSAGE_INVALID));
            elementoAnterior.setAttribute("title", '');
            elementoAnterior.parentNode.insertBefore(nuevoElemento, elementoAnterior.nextSibling);
        }
        elementoAnterior.focus()
    }
    function removeElement(name) {
        let nameError = 'error' + name
        let node = document.getElementsByName(nameError)[0]
        if (node) {
            let element = document.getElementsByName(name)[0]
            element.title = ""
            node.remove();
        }
    }
    function getCookie(cname) {
        var name = cname + "=";
        var ca = document.cookie.split(';');
        for (var i = 0; i < ca.length; i++) {
            var c = ca[i];
            while (c.charAt(0) === ' ') c = c.substring(1);
            if (c.indexOf(name) !== -1) return c.substring(name.length, c.length);
        }
        return "";
    }

    useEffect(() => {
        let authorization_sso = getCookie('CLARO_SSO_ACCESS_TOKEN');
        let DATA_USER = JSON.parse(sessionStorage.getItem("DATA_USER"));
        console.log("authorization_sso: " + authorization_sso);
        console.log("DATA_USER: " + DATA_USER);
        if (authorization_sso && !DATA_USER) {
            $("#c01LoginFloatDiv").hide()
            getUserInfo(authorization_sso)
        }
        if (authorization_sso && DATA_USER) {
            $("#c01LoginFloatDiv").hide()
            getUserInfo(authorization_sso)
        }
        // eslint-disable-next-line
    },[])

    useEffect(() => {
        let DATA_USER = JSON.parse(sessionStorage.getItem("DATA_USER"));
        if (DATA_USER) {
            hideAndReplaceComponets(DATA_USER)
            console.log("hideAndReplaceComponets if")
        }
        var cookiename = getCookie('LoginUser');
        if (!cookiename) {
            console.log("cookiename: ", cookiename)
            clearSessionStorage()
            $("#c01LoginFormReactDO").show()
            $("#c01LoginFloatDiv").show()
            $("#contentNameUser").text("")
            $("#bloqueUser").hide()
        } else {
            WriteCookie()
        }
        new WOW().init();
        let Consulta_de_Saldo = localStorage.getItem('Consulta_de_Saldo') ? JSON.parse(localStorage.getItem('Consulta_de_Saldo')) : '';
        if (Consulta_de_Saldo !== '') {
            getDataConsultaSaldo(Consulta_de_Saldo)
            $("#c01LoginFormReactDO").hide()
        }
        validForm("formLoginReactDO");
        // eslint-disable-next-line
    }, [])

    function randomItemGenerator() {
        let myArray = dataConsultaSaldo
        return myArray[Math.floor(Math.random() * myArray.length)]
    }

    useEffect(() => {
        setInterval(() => {
            if (dataConsultaSaldo.length) {
                let randomItemGeneratorVal = randomItemGenerator();
                setRandomItem(randomItemGeneratorVal)
            }
        }, 10000)
        // eslint-disable-next-line
    }, [dataConsultaSaldo], [])

    const handleShowPass = () => {
        const typeEye = passwordRef.current.getAttribute('type' )=== 'password' ? 'ico-eye-off' : 'ico-eye'
        eyeIcoRef.current.setAttribute('class', typeEye);
        const type = passwordRef.current.getAttribute('type') === 'password' ? 'text' : 'password';
        passwordRef.current.setAttribute('type', type);
    }

    async function getDataConsultaSaldo(Consulta_de_Saldo = '') {
        if (Consulta_de_Saldo?.length) {
            let ConsultaJson = Consulta_de_Saldo
            await setDataConsultaSaldo(ConsultaJson)
            await setRandomItem(ConsultaJson[0])
        }
    }
    function onSubmitLoginAppReact(e) {
        e.preventDefault();
        clearStatusErrorAppReact()
        let inputusername = $("input[name='username'")
        let inputpassword = $("input[name='password'")
        let btnLogin = $("#btnLogin")
        var error = false;

        if (error === false) {
            inputusername.prop("disabled", true)
            inputpassword.prop("disabled", true)
            btnLogin.prop("disabled", true)
            sendLoginAppReact(inputusername, inputpassword, btnLogin);
        }
    }
    async function getAssociatedAccounts(DATA_USER){
        $("#c01LoginFloatDiv").hide()
        console.log("user.loginName: ", DATA_USER.loginname)
        let url_servicio_web = urlServicioWeb
        const Accounts = {
            method: 'GET',
            url: url_servicio_web+"AssociatedAccountsService?user.loginName="+DATA_USER.loginname,
            headers: {
                'Accept': "application/json",
                'Content-Type': "application/json"
            },
        };
        let responseAccounts = await axios(Accounts)
        let dataPrivilege = responseAccounts?.data.length ? responseAccounts?.data[0] : null

        let dataArrayConsultaAll = [], dataArray = null
        let dataArrayConsultaPending = [], dataArrayConsultaPaid = []
        
        if (dataPrivilege){
            let newArrayAccounts = dataPrivilege.privilege.filter(
                item => (item?.manageableAsset?.partyProfile?.id === "2" || item?.manageableAsset?.partyProfile?.id === "3")
                )
            if(newArrayAccounts.length){
                
                for( let i = 0; i < newArrayAccounts.length; i++){
                    dataArray = await WriteConsulta_de_Saldo(DATA_USER, newArrayAccounts[i]?.manageableAsset)

                    var today = new Date();
                    
                    dataArray.map((item, index) => {
                        var paymentDueDate = item?.paymentDueDate ? new Date(item.paymentDueDate) : new Date();

                        if (item.state === "Paid")  dataArrayConsultaPaid.push(item) 
                        
                        if ((item.state === "Pending" || item.state === "Overdue") && today >= paymentDueDate)  dataArrayConsultaPending.push(item) 
                    })
                }
            }else{
                console.log("Error getting Postpago: manageableAsset?.partyProfile?.id ", dataPrivilege?.privilege)
            }
        }
        if(dataArrayConsultaPending?.length){
            if (dataArrayConsultaPending?.length > 1 ){
                let newTitle = DATA_USER?.name ? DATA_USER?.name+", ¡Una de tus facturas está vencida!" : "¡Una de tus facturas está vencida!"
                dataArrayConsultaAll.push({
                    title: newTitle,
                    description: "Paga tus facturas a unos cuantos clicks y sin salir de casa. ¡Es fácil y sencillo!",
                    // price: dataInvoiceDetailCurrentPeriod?.remainingAmount ? dataInvoiceDetailCurrentPeriod.remainingAmount.value : '',
                    validity: true,
                    goTo: 'Pagar aquí',
                    urlGoTo: 'https://miclaro.claro.com.do/#/Pagos',
                    toShow: true,
                    // toDay: date2.toLocaleString(),
                })
            }else{
                let newTitle = DATA_USER?.name ? DATA_USER?.name+", ¡Tu factura está vencida!" : "¡Tu factura está vencida!"
                dataArrayConsultaAll.push({
                    title: newTitle,
                    description: "Paga tu factura a unos cuantos clicks y sin salir de casa. ¡Es fácil y sencillo!",
                    // price: dataInvoiceDetailCurrentPeriod?.remainingAmount ? dataInvoiceDetailCurrentPeriod.remainingAmount.value : '',
                    validity: true,
                    goTo: 'Pagar aquí',
                    urlGoTo: 'https://miclaro.claro.com.do/#/Pagos',
                    toShow: true,
                    // toDay: date2.toLocaleString(),
                })
            }
        }
        if(dataArrayConsultaAll.length){
            localStorage.setItem('Consulta_de_Saldo', JSON.stringify(dataArrayConsultaAll))
            getDataConsultaSaldo(dataArrayConsultaAll)
            $("#c01LoginFloatDiv").show()
        }
        console.log("dataArrayConsultaAll:", dataArrayConsultaAll, dataArrayConsultaPaid, dataArrayConsultaPending, dataArrayConsultaPending?.length)
        
    }
    
    async function WriteConsulta_de_Saldo(DATA_USER, Accounts) {
        let relatedParty = Accounts?.id
        let url_servicio_web = urlServicioWeb
        const Notificaciones = {
            method: 'GET',
            url: url_servicio_web+"CustomerBillService?relatedParty.id="+relatedParty,
            headers: {
                'Accept': "application/json",
                'Content-Type': "application/json"
            },
        };

        let responseNoti = await axios(Notificaciones)
        console.log("responseNoti- getNotificaciones------------------------->", responseNoti?.data);
        let dataRespose = responseNoti?.data        
        if(dataRespose){
            return dataRespose;
        }else{
            return null
        }
    }
    function WriteCookie() {
        let Token = sessionStorage.getItem('AUTHORIZATION_TOKEN')
        var now = new Date();
        now.setMinutes(now.getMinutes() + 3 )
        document.cookie = 'LoginUser='+Token+';expires='+now.toUTCString()+';path=/';
    }
    async function getUserInfo(){
        let authorization_sso = getCookie('CLARO_SSO_ACCESS_TOKEN');
        let dataLogin = 'token=' + authorization_sso + '&refreshToken=' + getCookie('CLARO_SSO_REFRESH_TOKEN') + '&expiresIn=' + getCookie('CLARO_SSO_EXPIRES_IN');
        let url_servicio_web = urlServicioWeb
        await $.ajax({
            url: url_servicio_web+'login_automatic?' + dataLogin,
            type: "POST",
            dataType: "json",
            contentType: "application/json; charset=utf-8",
            success: function (data) {
                console.log("response data login_automatic: ", data);
                //set css error from a type response

                let token = data.access_token
                let code = data.code
                let requestID = data?.authnRequestID || ""
                let assertionID = data?.assertionID || ""

                sessionStorage.setItem("AUTHORIZATION_TOKEN", token)
                sessionStorage.setItem("DATA_AUTH_REQUEST_ID", requestID)
                sessionStorage.setItem("DATA_AUTH_ASSERTON_ID", assertionID)
                sessionStorage.setItem("AUTHORIZATION_CODE", code)
                sessionStorage.setItem("DATA_USER", JSON.stringify(data))
                localStorage.setItem("authorization_sso", token)
                $("#fieldusername").addClass('success');
                $("#fieldpassword").addClass('success');
                muestraLogin("show")
                hideAndReplaceComponets(data)
                WriteCookie()
                notificacionHidden === "false" ? getAssociatedAccounts(data) : null
                document.cookie = "CLARO_SSO_ACCESS_TOKEN=" + data.CLARO_SSO_ACCESS_TOKEN + "; max-age=900; domain=.claro.com.do; path=/";
                document.cookie = "authorization_sso=" + data.CLARO_SSO_ACCESS_TOKEN + "; max-age=900; domain=.claro.com.do; path=/";
                // document.cookie = "CLARO_SSO_REFRESH_TOKEN=" + data.CLARO_SSO_REFRESH_TOKEN + "; max-age=900; domain=.claro.com.do; path=/";
                document.cookie = "CLARO_SSO_EXPIRES_IN=" + data.CLARO_SSO_EXPIRES_IN + "; max-age=900; domain=.claro.com.do; path=/";
                document.cookie = "CLARO_SSO_CLIENT_ID=" + data.CLARO_SSO_CLIENT_ID + "; max-age=900; domain=.claro.com.do; path=/";
            },
            error: function (xhr, ajaxOptions, thrownError) {
                console.log('*****Error:');
                console.log('*****$("#bloqueUser"):', $("#bloqueUser"));
                $("#bloqueUser").hide();
                $('#c01LoginFormReactDO').show();
                $('#miClaroOpciones').hide();
                $('#miClaroLiga').show();
                console.log('****$(#c01LoginFormReactDO):', $('#c01LoginFormReactDO')); 
                clearSessionStorage();
                console.log('*****clearSessionStorage');
                console.log('Error::::', thrownError);
                console.log(xhr.status, xhr.responseJSON, xhr);
                //errorSetDataStyleLogin(xhr)
            }
        });
    }
    function sendLoginAppReact(inputusername, inputpassword, btnLogin) {
        let url_servicio_web = urlServicioWeb
        var SendDatalogin = {
            username: username,
            password: password
        };
        var jsonSendData = JSON.stringify(SendDatalogin);
        $.ajax({
            url: url_servicio_web+'login',
            type: "POST",
            data: jsonSendData,
            dataType: "json",
            contentType: "application/json; charset=utf-8",
            success: function (data) {
                console.log("Data: ", data);
                if(!data?.error){
                    //set css error from a type response

                    let token = data.access_token
                    let code = data.code
                    let requestID = data?.authnRequestID || ""
                    let assertionID = data?.assertionID || ""

                    sessionStorage.setItem("AUTHORIZATION_TOKEN", token)
                    sessionStorage.setItem("DATA_AUTH_REQUEST_ID", requestID)
                    sessionStorage.setItem("DATA_AUTH_ASSERTON_ID", assertionID)
                    sessionStorage.setItem("AUTHORIZATION_CODE", code)
                    sessionStorage.setItem("DATA_USER", JSON.stringify(data))
                    localStorage.setItem("authorization_sso", token)
                    $("#fieldusername").addClass('success');
                    $("#fieldpassword").addClass('success');
                    muestraLogin("show")
                    hideAndReplaceComponets(data)
                    WriteCookie()
                    notificacionHidden === "false" ? getAssociatedAccounts(data) : null
                    document.cookie = "CLARO_SSO_ACCESS_TOKEN=" + data.CLARO_SSO_ACCESS_TOKEN + "; max-age=900; domain=" + window.location.host.replace('www.', '') + "; path=/";
                    document.cookie = "authorization_sso=" + data.CLARO_SSO_ACCESS_TOKEN + "; max-age=900; domain=" + window.location.host.replace('www.', '') + "; path=/";
                    // document.cookie = "CLARO_SSO_REFRESH_TOKEN=" + data.CLARO_SSO_REFRESH_TOKEN + "; max-age=900; domain=" + window.location.host.replace('www.', '') + "; path=/";
                    document.cookie = "CLARO_SSO_EXPIRES_IN=" + data.CLARO_SSO_EXPIRES_IN + "; max-age=900; domain=" + window.location.host.replace('www.', '') + "; path=/";
                    document.cookie = "CLARO_SSO_CLIENT_ID=" + data.CLARO_SSO_CLIENT_ID + "; max-age=900; domain=" + window.location.host.replace('www.', '') + "; path=/";
                }else{
                    errorSetDataStyleLogin(data)
                }
                inputusername.prop("disabled", false)
                inputpassword.prop("disabled", false)
                btnLogin.prop("disabled", false)
            },
            error: function (xhr, ajaxOptions, thrownError) {
                inputusername.prop("disabled", false)
                inputpassword.prop("disabled", false)
                btnLogin.prop("disabled", false)
                muestraLogin("hide")
                errorSetDataStyleLogin(xhr)
                clearSessionStorage()
                console.log(xhr.status, xhr.responseJSON, xhr);
            }
        });
    }
    function errorSetDataStyleLogin(msgError = false) {
        console.log('Error errorSetDataStyleLogin:::', msgError);
        let msg = 'Ha ocurrido un error, vuelva a intentarlo'
        if (msgError) {
            let responseJSONError = msgError?.responseText ? JSON.parse( msgError?.responseText.replaceAll("'", '"') ) : msgError
            let error_Desc = ""
            if (responseJSONError?.errorMessage) {
                error_Desc = responseJSONError?.errorMessage
            } 
            else {
                error_Desc = responseJSONError?.error
            }
            msg = error_Desc
            console.log('Error error_Desc:::', error_Desc);
            if(error_Desc == undefined || error_Desc == null) {
            	  msg = 'Ha ocurrido un error, vuelva a intentarlo'
            } else {
	            if (error_Desc.indexOf("nimo de intentos")>0 && error_Desc.indexOf("15 minutos")>=0){
	                msg = "Has alcanzado el mínimo de intentos permitidos, tu usuario ha sido bloqueado por 15 minutos";
	            }
	            if (error_Desc.indexOf("nimo de intentos")>0 && error_Desc.indexOf("1 hora")>=0){
	                msg = "Has alcanzado el mínimo de intentos permitidos, tu usuario ha sido bloqueado por 1 hora";
	            }
	            if (error_Desc.indexOf("nimo de intentos")>0 && error_Desc.indexOf("12 horas")>=0){
	                msg = "Has alcanzado el mínimo de intentos permitidos, tu usuario ha sido bloqueado por 12 horas";
	            }
	            if (error_Desc.indexOf("a incorrecta") >= 0) {
	                msg = "Contraseña incorrecta.";
	            }
	            if (error_Desc.indexOf("User_not_registered_in_SSO") >=0 ) {
	                msg = "El usuario no esta registrado";
	            }
	            if (error_Desc.indexOf("Password_not_valid")>=0) {
	                msg = "La contraseña que ingresaste es incorrecta";
	            }
	            if (error_Desc.indexOf("15+minutes") >= 0) {
	                msg = "Ha excedido el máximo de intentos permitidos. Su cuenta ha sido bloqueada temporalmente por 15 minutos, inténtelo más tarde.";
	            }
	            if (error_Desc.indexOf("1+hour") >=0 ) {
	                msg = "Ha excedido el máximo de intentos permitidos. Su cuenta ha sido bloqueada temporalmente por 1 hora, inténtelo más tarde.";
	            }
	            if (error_Desc.indexOf("12+hours") >=0 ) {
	                msg = "Ha excedido el máximo de intentos permitidos. Su cuenta ha sido bloqueada temporalmente por 12 horas, inténtelo más tarde.";
	            }
	            if (msg.indexOf("Session Not Found") >=0) {
	                msg = "Usuario no encontrado";
	            }
	            if (msg.indexOf("Internal Server Error")>=0) {
	                msg = 'Ha ocurrido un error, vuelva a intentarlo'
	            }
	        }
        }
        $("#errorMsgLogin").text(msg)
        $("#errorMsgLogin").show()
        $("#fieldusername").addClass('error');
        $("#fieldpassword").addClass('error');
    }
    function clearSessionStorage() {
        sessionStorage.removeItem("AUTHORIZATION_TOKEN")
        sessionStorage.removeItem("DATA_AUTH_REQUEST_ID")
        sessionStorage.removeItem("DATA_AUTH_ASSERTON_ID")
        sessionStorage.removeItem("AUTHORIZATION_CODE")
        sessionStorage.removeItem("DATA_USER")
        sessionStorage.removeItem("authorization_sso")
        localStorage.removeItem('Consulta_de_Saldo');
        document.cookie = "CLARO_SSO_ACCESS_TOKEN=; max-age=0; domain=.claro.com.do";
        // document.cookie = "CLARO_SSO_REFRESH_TOKEN=; max-age=0; domain=.claro.com.do";
        document.cookie = "CLARO_SSO_EXPIRES_IN=; max-age=0; domain=.claro.com.do";
        document.cookie = "CLARO_SSO_CLIENT_ID=; max-age=0; domain=.claro.com.do";   
    }
    function muestraLogin(accion){
        if(accion === 'show') {
            $('#miClaroLiga').hide();
            $('#miClaroOpciones').show();
        } else {
            $('#miClaroLiga').show();
            $('#miClaroOpciones').hide();
        } 
    }
    function clearStatusErrorAppReact() {
        $("#fieldpassword").removeClass('error');
        $("#fieldpassword").removeClass('success');
        $("#fieldusername").removeClass('error');
        $("#fieldusername").removeClass('success');
        $("#errorMsgLogin").hide();
    }
    function capitalize(s)
    {
        return s && String(s[0]).toUpperCase() + String(s).slice(1);
    }
    function hideAndReplaceComponets(data) {
        console.log("hideAndReplaceComponets React function", data)
        console.log("pruebassss", capitalize(data?.name?.split(" ",1)[0].toLowerCase()))
        $("#bloqueUser").show()
        let welcomeUsername = '<p class="textAtom blanco text3Xl textAtomBold title" id="welcomeUsername"> Hola ' + data?.name + '</p>';
        $("#sliderItemContTitle").prepend(welcomeUsername);
        $("#contentNameUser").text(capitalize(data?.name?.split(" ",1)[0].toLowerCase()))
        $("#cp01BarClient").hide()
        $("#c01LoginFormReactDO").hide()
        $("#c01LoginFloatDiv").show()
        console.log("c01LoginFormReactDO hideAndReplaceComponets")
    }


    function onChange(e) {
        clearStatusErrorAppReact(e)
        let target = e.target
        if (target.name === 'password') {
            let cleanPwd = target.value.replace(/[ /",ñ]/gi, '');
            setPassWord(cleanPwd)
        }
        if (target.name === 'username') {
            let cleanUser = target.value.replace(/[^a-zA-Z0-9\s]/gi, '');
            setUserName(cleanUser)
        }        
    }
    
    return (
        <>
            <section className="c01LoginWrap" id="c01LoginFormReactDO">
                <div className="c01Login">
                    <div className="c01LoginCont">
                        <div className="c01LoginForm">
                            <h3 className="h4">
                                ¿Eres cliente Claro? <span>Ingresa desde aqu&iacute; a  Mi Claro y Tienda en L&iacute;nea</span>
                            </h3>
                            {/* <C01LoginPeopleOrgForm
                            onSubmit={onSubmitForm}
                        /> */}
                            <form action="#" onSubmit={(e) => onSubmitLoginAppReact(e)} name="formLoginReactDO">
                                <div className="c01LoginFormLeft">
                                    <fieldset>
                                        <input className="" id="rut" name="username" type="text" placeholder="Nombre de usuario" value={username}
                                            minLength="8" maxLength="30" autoComplete="off" step="" required
                                            onChange={(e) => onChange(e)} />
                                        <span className="errorField light" id="errorMsgLogin" style={{ display: "none" }} ></span>
                                    </fieldset>
                                    <fieldset className='c01LoginFormLeftPass'>
                                        <input className="" name="password" type="password" placeholder="Contrase&ntilde;a" value={password}
                                            maxLength="30" autoComplete="off" required
                                            onChange={(e) => onChange(e)} ref={passwordRef} />
                                            <i className='ico-eye' onClick={handleShowPass} ref={eyeIcoRef}></i>
                                    </fieldset>
                                </div>
                                <div className="c01LoginFormRight">
                                    <fieldset>
                                        <button className="btn btnTransRojo8" type="submit" id="btnLogin">Iniciar Sesi&oacute;n</button>
                                    </fieldset>
                                    <p>
                                        <strong>¿No tienes cuenta? </strong>
                                        <a className="textLink" href="https://usuario.claro.com.do/account/register/document-validate" target="_blank" rel="noreferrer">Reg&iacute;strate</a>
                                    </p>
                                </div>
                            </form>
                        </div>
                        {/* <div className="c01LoginFloat wow">

                        </div> */}
                    </div>
                </div>
            </section>
            <section className={`c01Noty ${classProps}`}>
                <div className="c01Login">
                    <div className="c01LoginCont">
                        <div className="c01LoginForm">
                            
                        </div>
                        {
                            randomItem?.title && notificacionHidden === "false"
                                ?
                                <div className="c01LoginFloat wow" id="c01LoginFloatDiv">
                                    <h3 className="h5">{randomItem.title}</h3>
                                    <div className="c01LoginFloatText">
                                        <div className='c01LFTCont'>
                                            {
                                                randomItem?.description &&
                                                <p>
                                                    {randomItem.description}
                                                </p>
                                            }
                                            {
                                                randomItem?.price &&
                                                <p className='price'>
                                                    {randomItem.price}
                                                </p>
                                            }
                                            {
                                                randomItem?.validity &&
                                                <p className='val'>
                                                    {randomItem.validity}

                                                </p>
                                            }

                                        </div>
                                        <div className="c01LFTbtn">
                                            {
                                                randomItem?.goTo &&
                                                <a className="btn btnTransBlancoNegro" href={randomItem?.urlGoTo ? randomItem?.urlGoTo : "#!"}>
                                                    {randomItem.goTo}
                                                </a>
                                            }
                                        </div>
                                    </div>
                                </div>
                                :
                                <>
                                {
                                    displayDefaultNoti === "false"
                                    ?
                                    <div className="c01LoginFloat wow" id="c01LoginFloatDiv">
                                        <h3 className="h5">{title}</h3>
                                        <div className="c01LoginFloatText">
                                            <div className='c01LFTCont'>
                                                {
                                                    description &&
                                                    <p>
                                                        {description}
                                                    </p>
                                                }
                                                {
                                                    price &&
                                                    <p className='price'>
                                                        {price}
                                                    </p>
                                                }
                                                {
                                                    validity &&
                                                    <p className='val'>
                                                        {validity}

                                                    </p>
                                                }

                                            </div>
                                            <div className="c01LFTbtn">
                                                <a className="btn btnTransBlancoNegro" href={urlGoTo} target={targetGoTo !== "" ? targetGoTo : "_self"}>
                                                    {goTo}
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                    : null
                                }
                                </>
                                
                        }
                    </div>
                </div>
            </section>
        </>
    )
}

C01Notification.defaultProps = {
    title: '¿Nos visitas de una Empresa o Pyme?',
    description: 'Descubre los servicios y promociones que preparamos para tu negocio',
    price: '',
    validity: '',
    // price: '$5,609.90',
    // validity: 'Vence 13 / Abril / 2020',
    goTo: 'Ir a Empresas',
    urlGoTo: '#!',
    targetGoTo: '_blank',
    classProps: '',
}

C01Notification.propTypes = {
    title: PropTypes.string,
    description: PropTypes.string,
    price: PropTypes.string,
    validity: PropTypes.string,
    goTo: PropTypes.string,
    urlGoTo: PropTypes.string,
    targetGoTo: PropTypes.string,
    classProps: PropTypes.string,
}

export default C01Notification
